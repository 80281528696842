.brand-wrapper {
  display: flex;
  align-items: center;
  width: 25px;
  transition: all 0.2s ease-in;
  @media (max-width: 576px) {
    width: 150px;
  }
  .box {
    height: 25px;
    aspect-ratio: 1;
    border-radius: 5px;
    background-color: rgb(206, 47, 47);
  }
  &:has(.box:hover) {
    width: 150px;
  }
}