#main_layout {
  position: relative;
  min-height: 100dvh;
  .floating-action-button {
    position: sticky;
    bottom: 25px;
    left: calc(100% - 75px);
    background: #090909;
    border: 1px solid gray;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
    cursor: pointer;
    a {
      color: rgba(255, 255, 255, 0.68);
      transition: all .3s ease-in-out;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.68);
      a {
        color: #090909;
      }
    }
  }
}
